import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Card, CardContent } from '@mui/material';
import emailjs from 'emailjs-com';

// Initialize EmailJS with your Public Key
emailjs.init('3bvcreckJW1KPR4Lg'); // Replace with your actual Public Key

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send form data via EmailJS
    emailjs.send('service_tgqhiuo', 'template_egok5wc', formData, '3bvcreckJW1KPR4Lg') // Replace with actual IDs and Public Key
      .then((result) => {
        alert('Message sent successfully!');
        setFormData({ name: '', email: '', message: '' });
      }, (error) => {
        console.error('Failed to send message:', error.text);
        alert('Failed to send message. Please try again later.');
      });
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom style={{ textAlign: 'center', margin: '30px 0', color: '#00ff7f' }}>
        Contact Me
      </Typography>
      <Card style={{ maxWidth: '600px', margin: 'auto', backgroundColor: '#1d1d1d', color: '#ffffff' }}>
        <CardContent>
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              label="Your Name"
              name="name"
              variant="outlined"
              value={formData.name}
              onChange={handleChange}
              required
              style={{ marginBottom: '20px' }}
              InputProps={{
                style: { color: '#ffffff' },
              }}
              InputLabelProps={{
                style: { color: '#00ff7f' },
              }}
            />
            <TextField
              label="Your Email"
              name="email"
              variant="outlined"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
              style={{ marginBottom: '20px' }}
              InputProps={{
                style: { color: '#ffffff' },
              }}
              InputLabelProps={{
                style: { color: '#00ff7f' },
              }}
            />
            <TextField
              label="Your Message"
              name="message"
              variant="outlined"
              multiline
              rows={4}
              value={formData.message}
              onChange={handleChange}
              required
              style={{ marginBottom: '20px' }}
              InputProps={{
                style: { color: '#ffffff' },
              }}
              InputLabelProps={{
                style: { color: '#00ff7f' },
              }}
            />
            <Button type="submit" variant="contained" color="primary" style={{ backgroundColor: '#00ff7f', color: '#000000' }}>
              Send Message
            </Button>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Contact;
