import React, { useState } from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia } from '@mui/material';
import { motion } from 'framer-motion';
import { FaLaptopCode, FaShoppingCart, FaCashRegister } from 'react-icons/fa';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const projectList = [
  {
    title: 'VV1',
    description: 'Developed an internal management ERP software that integrates order management, CRM, inventory control, and warehouse logistics functionalities. The system streamlines order processing, inventory tracking, and warehouse management while also providing accounting functionalities, significantly boosting operational efficiency.',
    techStack: ['PHP', 'Python', 'CodeIgniter', 'MySQL', 'Docker'],
    image: 'https://ipdev-portfolio.s3.us-west-1.amazonaws.com/vv1.png',
  },
  {
    title: 'KPP',
    description: 'Created a client dashboard portal to monitor sales and inventory status in real time, including comprehensive access to Amazon data and client order management capabilities. The portal enhances client engagement and decision-making by providing up-to-date, actionable insights.',
    techStack: ['React', 'PHP', 'Laravel', 'MongoDB', 'NodeJS'],
    image: 'https://ipdev-portfolio.s3.us-west-1.amazonaws.com/kpp.png',
  },
  {
    title: 'WSFulfillment',
    description: 'Developed an order management panel tailored for 3PL clients, allowing them to add and manage orders, track inventory status, and generate detailed reports. The platform enhances operational transparency and supports efficient logistics management for third-party logistics providers.',
    techStack: ['Python', 'Django', 'MySQL'],
    image: 'https://ipdev-portfolio.s3.us-west-1.amazonaws.com/wsfulfillment.png',
  },
  {
    title: 'KMS',
    description: 'Developed a robust management system to streamline order management, inventory control, and purchase order (PO) management processes. The solution enhances operational efficiency by providing real-time inventory tracking, automated order processing, and comprehensive PO management functionalities, ensuring smooth supply chain operations.',
    techStack: ['PHP', 'CodeIgniter', 'MySQL'],
    image: 'https://ipdev-portfolio.s3.us-west-1.amazonaws.com/kms.png',
  },
  {
    title: 'ShipmentsPanel',
    description: 'A comprehensive management panel designed to streamline the tracking of incoming and outgoing shipments. This tool offers real-time updates on shipment statuses, ensuring all stakeholders are informed promptly. Clients can securely upload and manage essential documents related to overseas shipments, enhancing the transparency and efficiency of the shipping process. The panel is built to handle dynamic data and provides an intuitive interface for seamless document management and live communication with stakeholders.',
    techStack: ['NodeJS', 'React', 'MongoDB', 'Docker'],
    image: 'https://ipdev-portfolio.s3.us-west-1.amazonaws.com/shipments_panel.png',
  },
  {
    title: 'Simple Inventory Management',
    description: 'Developed a simple inventory management system for small businesses to track inventory levels, sales, and purchase orders. The system provides real-time inventory tracking and generates reports to help businesses make informed decisions.',
    techStack: ['Python', 'Flask', 'MongoDB'],
    image: 'https://ipdev-portfolio.s3.us-west-1.amazonaws.com/inventory_management.png',
  },
  {
    title: 'IPExpense',
    description: 'Developed a personal expense tracking application to help users manage their finances effectively. The application allows users to track their expenses, set budgets, and generate reports to analyze their spending habits. The application is designed to be user-friendly and intuitive, making it easy for users to manage their finances on the go.',
    techStack: ['PHP', 'Laravel', 'PostgreSQL'],
    image: 'https://ipdev-portfolio.s3.us-west-1.amazonaws.com/ipexpense_screenshot.png',
    git: 'https://github.com/ipcs110/ipexpense',
  }
];

const ecommerceProjects = [
  {
    title: 'WordPress Website 1',
    description: 'Developed an e-commerce website selling customized bags with bulk price discounts and customizable options, enhancing user engagement and conversion rates.',
    image: 'https://ipdev-portfolio.s3.us-west-1.amazonaws.com/bagworksny.png',
  },
  {
    title: 'Shopify 1',
    description: 'Designed an interactive Shopify website for a modern designed bag business, featuring dynamic product options and an intuitive user interface.',
    image: 'https://ipdev-portfolio.s3.us-west-1.amazonaws.com/shopify.png',
  },
  {
    title: 'Shopify 2',
    description: 'Built a Shopify website for a mattress company, offering various product options and configurations, optimized for SEO and user experience.',
    image: 'https://ipdev-portfolio.s3.us-west-1.amazonaws.com/shopify2.png',
  },  
  {
    title: 'Shopify 3',
    description: 'Built a Shopify website for a toy company, offering various product options and configurations, optimized for SEO and user experience.',
    image: 'https://ipdev-portfolio.s3.us-west-1.amazonaws.com/shopify3.png',
  },
  {
    title: 'Shopify 4',
    description: 'Developed a Shopify website for a k-beauty skincare brand.',
    image: 'https://ipdev-portfolio.s3.us-west-1.amazonaws.com/skinfood.png',
  }
];

const posProjects = [
  {
    title: 'Retail POS',
    description: 'Developed Web retail software for product checkout, payment processing, receipt printing, end-of-day (EOD) processes, and report generation. Includes inventory control for multiple locations.',
    techStack: ['PHP', 'HTML', 'MySQL'],
    image: 'https://ipdev-portfolio.s3.us-west-1.amazonaws.com/pos.png',
  },
  {
    title: 'Retail POS 2',
    description: 'Developed a retail POS system for a small business, including product management, sales tracking, and inventory control functionalities. The system streamlines the checkout process and provides real-time sales data for business owners.',
    techStack: ['Python', 'Django', 'PostgreSQL'],
    image: 'https://ipdev-portfolio.s3.us-west-1.amazonaws.com/lh_pos.png',
  }
];

const Projects = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [lightboxImages, setLightboxImages] = useState([]);

  const openLightbox = (index, projects) => {
    setLightboxImages(projects.map((project) => project.image));
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <Container>
      {/* Main Projects Section */}
      <Typography variant="h4" gutterBottom style={{ textAlign: 'center', margin: '30px 0' }}>
        <FaLaptopCode style={{ marginRight: '10px' }} /> Projects
      </Typography>
      <Grid container spacing={4}>
        {projectList.map((project, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <motion.div whileHover={{ scale: 1.05 }}>
              <Card style={{ backgroundColor: '#1d1d1d', color: '#ffffff' }}>
                {/* Thumbnail Click Area */}
                <CardMedia
                  component="img"
                  height="200"
                  image={project.image}
                  alt={`${project.title} Screenshot`}
                  style={{ cursor: 'pointer' }} // Add cursor pointer
                  onClick={() => openLightbox(index, projectList)} // Only open Lightbox on image click
                />
                <CardContent>
                  <Typography variant="h5" component="div">{project.title}</Typography>
                  <Typography variant="body2" color="text.secondary">{project.description}</Typography>
                  <Typography variant="body2" color="text.secondary" style={{ marginTop: '10px' }}>
                    <strong>Tech Stack:</strong> {project.techStack.join(', ')}
                  </Typography>
                  {project.git && (
                    <Typography variant="body2" color="primary" style={{ marginTop: '10px' }}>
                      <a href={project.git} target="_blank" rel="noopener noreferrer" style={{ color: '#ffffff', textDecoration: 'none' }}>GitHub Repository</a>
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>

      {/* E-commerce Websites Section */}
      <Typography variant="h4" gutterBottom style={{ textAlign: 'center', margin: '30px 40px' }}>
        <FaShoppingCart style={{ marginRight: '10px' }} /> E-commerce Websites
      </Typography>
      <Grid container spacing={4}>
        {ecommerceProjects.map((project, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <motion.div whileHover={{ scale: 1.05 }}>
              <Card style={{ backgroundColor: '#1d1d1d', color: '#ffffff' }}>
                {/* Thumbnail Click Area */}
                <CardMedia
                  component="img"
                  height="200"
                  image={project.image}
                  alt={`${project.title} Screenshot`}
                  style={{ cursor: 'pointer' }} // Add cursor pointer
                  onClick={() => openLightbox(index, ecommerceProjects)} // Only open Lightbox on image click
                />
                <CardContent>
                  <Typography variant="h5" component="div">{project.title}</Typography>
                  <Typography variant="body2" color="text.secondary">{project.description}</Typography>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>

      {/* Retail POS Section */}
      <Typography variant="h4" gutterBottom style={{ textAlign: 'center', margin: '30px 40px' }}>
        <FaCashRegister style={{ marginRight: '10px' }} /> Retail Management
      </Typography>
      <Grid container spacing={4}>
        {posProjects.map((project, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <motion.div whileHover={{ scale: 1.05 }}>
              <Card style={{ backgroundColor: '#1d1d1d', color: '#ffffff' }}>
                {/* Thumbnail Click Area */}
                <CardMedia
                  component="img"
                  height="200"
                  image={project.image}
                  alt={`${project.title} Screenshot`}
                  style={{ cursor: 'pointer' }} // Add cursor pointer
                  onClick={() => openLightbox(index, posProjects)} // Only open Lightbox on image click
                />
                <CardContent>
                  <Typography variant="h5" component="div">{project.title}</Typography>
                  <Typography variant="body2" color="text.secondary">{project.description}</Typography>
                  <Typography variant="body2" color="text.secondary" style={{ marginTop: '10px' }}>
                    <strong>Tech Stack:</strong> {project.techStack.join(', ')}
                  </Typography>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>

      {/* Lightbox component */}
      {isOpen && (
        <Lightbox
          mainSrc={lightboxImages[photoIndex]}
          nextSrc={lightboxImages[(photoIndex + 1) % lightboxImages.length]}
          prevSrc={lightboxImages[(photoIndex + lightboxImages.length - 1) % lightboxImages.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + lightboxImages.length - 1) % lightboxImages.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % lightboxImages.length)}
        />
      )}
    </Container>
  );
};

export default Projects;
