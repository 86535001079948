import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',  // Dark mode
    primary: {
      main: '#00ff7f',  // Neon green for primary elements
    },
    secondary: {
      main: '#1e90ff',  // Neon blue for secondary elements
    },
    background: {
      default: '#121212',  // Dark background
      paper: '#1d1d1d',    // Slightly lighter background for paper elements
    },
    text: {
      primary: '#ffffff',  // White text for contrast
      secondary: '#00ff7f',  // Neon green text for secondary elements
    },
  },
  typography: {
    fontFamily: '"Fira Code", "Courier New", monospace',  // Monospaced font for a code look
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 600,
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.9rem',
      color: '#00ff7f',  // Secondary text in neon green
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);
