import React from 'react';
import { Container, Typography, Avatar, Box, Grid, Paper } from '@mui/material';
import { motion } from 'framer-motion';
import { FaCode } from 'react-icons/fa';  // Corrected icon import

const Profile = () => {
  const skills = {    
    'Core Skills': 'Software Development, Full-stack Development, Backend Development, Frontend Development, RESTful APIs, Microservices, Agile/Scrum, TDD, CI/CD, API Integration, Data Analysis, Process Optimization, Business Requirements Analysis, Scalable Solutions, Custom Solutions.',
    'Programming Languages': 'Python, PHP, JavaScript, Typescript, Java, C#, SQL',
    'Web Development': 'React, jQuery, AngularJS, NodeJS, Flask, Django, Laravel, CodeIgniter, Spring Boot',
    'E-commerce & CMS Platforms': 'Shopify, WordPress, WooCommerce',
    'Databases': 'MySQL, PostgreSQL, MongoDB, DynamoDB',
    'DevOps': 'AWS, Azure, Docker, Git'
  };

  return (
    <Container>
            <Box 
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        style={{ textAlign: 'center', padding: '50px 0' }}
      >
        <Avatar 
          alt="Isak Park" 
          src="your-photo-url" 
          sx={{ width: 150, height: 150, margin: 'auto', border: '3px solid #00ff7f' }} 
        />
        <Typography variant="h4" gutterBottom>
          <FaCode style={{ marginRight: '10px' }} /> Isak Park
        </Typography>
        <Typography variant="h6" style={{ color: '#1e90ff', marginBottom: '20px' }}>Software Engineer | Full Stack Developer</Typography>
        <Typography variant="body1" sx={{ maxWidth: '700px', margin: 'auto', marginBottom: '20px' }}>
          I am a Software Engineer with extensive experience in full-stack development, backend development, system architecture, and software development. I have a proven track record in designing and implementing ERP & CRM systems, significantly improving operational efficiency and data accuracy. I have successfully led teams of developers to integrate complex APIs, developed scalable software solutions, and engineered client portals for real-time monitoring and seamless order processing.
        </Typography>
        <Typography variant="body1" sx={{ maxWidth: '700px', margin: 'auto', marginBottom: '20px' }}>
          My expertise includes a diverse range of technologies such as AWS, Azure, Docker, Git, and DevOps practices, combined with programming languages like PHP, Python, Java, JavaScript, and React. I have in-depth experience with frameworks such as Flask, Django, Laravel, and CodeIgniter, ensuring robust and maintainable applications.
        </Typography>
        <Typography variant="body1" sx={{ maxWidth: '700px', margin: 'auto', marginBottom: '20px' }}>
          Throughout my career, I have consistently driven impactful solutions in collaborative environments, leveraging my skills in software development lifecycle management, agile methodologies, and innovative problem-solving. I aspire to continue developing custom software solutions that meet complex business needs while optimizing processes and enhancing user experience.
        </Typography>
      </Box>
      {/* Skills Section */}
      <Typography variant="h4" gutterBottom style={{ textAlign: 'center', margin: '30px 0' }}>
        Skills
      </Typography>
      <Grid container spacing={3}>
        {Object.entries(skills).map(([category, list], index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <motion.div whileHover={{ scale: 1.05 }}>
              <Paper elevation={3} style={{ padding: '20px', margin: '10px', backgroundColor: '#1d1d1d' }}>
                <Typography variant="h6" style={{ marginBottom: '10px' }}>{category}</Typography>
                <Typography variant="body2">{list}</Typography>
              </Paper>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Profile;
