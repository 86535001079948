import React from 'react';
import { Container, Typography, Card, CardContent, Grid, Paper } from '@mui/material';
import { motion } from 'framer-motion';

const experiences = [
  {
    title: 'Senior Software Engineer',
    company: 'Kreassive, Torrance',
    period: 'December 2014 – Present',
    details: [
      'Developed and implemented an ERP system integrating order management, CRM, inventory control, and warehouse logistics functionalities, boosting operational efficiency by 40%.',
      'Integrated Amazon API and eBay API to synchronize sales and inventory data, enhancing data accuracy and reducing manual data entry by 70%.',
      'Designed and deployed a client portal using ReactJS for real-time monitoring of sales and inventory, featuring customizable reporting functionalities with graphical representations.',
      'Engineered a client panel to enable seamless order processing and sales tracking across multiple platforms, reducing order processing time.',
      'Led code reviews and mentored a team of 3 developers and 2 graphic designers, ensuring adherence to coding standards and best practices, improving code quality.',
      'Managed backend development with MySQL and MongoDB and frontend development using ReactJS, ensuring a seamless user experience across platforms and reducing system downtime.',
      'Implemented DevOps practices with Git, Docker, AWS, and Azure, reducing deployment time and enhancing system scalability by 30%.'
    ],
  },
  {
    title: 'Lead Programmer',
    company: 'Hamee US, Torrance',
    period: 'November 2013 – December 2014',
    details: [
      'Led a team of two developers in adapting the ERP system "NextEngine" for the U.S. market, integrating U.S. marketplace APIs and enhancing system efficiency.',
      'Implemented advanced data analytics features within the ERP system, enabling real-time insights into sales trends and inventory management, increasing sales forecast accuracy.',
      'Orchestrated the migration of legacy systems to modern cloud infrastructure on Heroku, reducing maintenance costs by 20% and enhancing system scalability.',
      'Developed and implemented a comprehensive disaster recovery plan for the ERP system, ensuring business continuity and reducing system downtime by 20%.',
      'Conducted regular performance assessments of the ERP system and implemented optimization strategies for a reliable and scalable solution, improving system response time.',
      'Ensured high-quality software delivery by writing clean, efficient, and maintainable code following industry standards, conducting rigorous testing and debugging processes, and reducing bugs by 40%.'
    ],
  }
];

const educationAndCertifications = [
  {
    title: 'Bachelor of Science in Computer Science',
    institution: 'Western Governors University (WGU)',
  },
  {
    title: 'Master of Science in Computer Science (In Progress)',
    institution: 'Georgia Institute of Technology',
  },
  {
    title: 'AWS Developer Associate',
    details: [
      'Demonstrated proficiency in developing and deploying applications on the AWS platform, including services such as EC2, S3, Lambda, DynamoDB, and API Gateway.',
      'Acquired skills in leveraging AWS SDKs to integrate applications with AWS services, optimizing performance and scalability for cloud-based solutions.'
    ]
  },
  {
    title: 'Zend PHP Certification',
    details: [
      'Mastered advanced PHP programming concepts such as OOP, MVC architecture, and security best practices, enabling the development of efficient web applications.',
      'Acquired expertise in PHP frameworks like Zend Framework and Laravel to streamline development and enhance code reusability for scalable web applications.'
    ]
  },
  {
    title: 'ITIL Foundation Certification',
    details: [
      'Gained a comprehensive understanding of IT service management best practices and principles, including service lifecycle stages, processes, and functions.',
      'Developed skills in aligning IT services with business objectives, improving service quality, and optimizing IT service delivery and support processes.'      
    ]
  }
  ,
  {
    title: 'Linux Professional Institute Certification (Linux Essentials)',
    details: [
      'Demonstrated foundational knowledge of Linux operating systems, including installation, configuration, command-line usage, and basic system administration tasks.',
      'Acquired skills in managing user accounts, file systems, and system security, enabling efficient operation and maintenance of Linux-based systems.'
    ]
  }
];

const Experience = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom style={{ textAlign: 'center', margin: '30px 0' }}>
        Experience
      </Typography>
      <Grid container spacing={4}>
        {experiences.map((exp, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <motion.div whileHover={{ scale: 1.05 }}>
              <Card>
                <CardContent>
                  <Typography variant="h6">{exp.title} | {exp.company}</Typography>
                  <Typography variant="subtitle2" color="textSecondary">{exp.period}</Typography>
                  <ul>
                    {exp.details.map((detail, i) => (
                      <li key={i}>
                        <Typography variant="body2">{detail}</Typography>
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>

      {/* Education and Certifications Section */}
      <Typography variant="h4" gutterBottom style={{ textAlign: 'center', margin: '30px 20px' }}>
        Education & Certifications
      </Typography>
      <Grid container spacing={4}>
        {educationAndCertifications.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <motion.div whileHover={{ scale: 1.05 }}>
              <Paper elevation={3} style={{ padding: '20px', margin: '10px', backgroundColor: '#1d1d1d' }}>
                <Typography variant="h6" style={{ marginBottom: '10px' }}>{item.title}</Typography>
                {item.institution && (
                  <Typography variant="body2" style={{ color: '#00ff7f' }}>{item.institution}</Typography>
                )}
                {item.details && (
                  <ul>
                    {item.details.map((detail, i) => (
                      <li key={i}>
                        <Typography variant="body2">{detail}</Typography>
                      </li>
                    ))}
                  </ul>
                )}
              </Paper>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Experience;
